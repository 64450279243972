import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Gift } from '../components/gift'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import covid from '../assets/img/fitri/covid.svg'
import logoig from '../assets/img/aulia/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = 'rgb(89,103,88)'
let black = 'rgb(38,38,38)'

let id = 'rinda-yoga'
let inisial_co = 'Yoga'
let inisial_ce = 'Rinda'

let lengkap_co = (<>Yoga Yuniar Bahrul Musthafa</>)
let lengkap_ce = (<>Rinda Mustikah</>)

let bapak_co = 'Bapak H. Saring '
let ibu_co = 'Ibu Hj. Suci Rokhaniah'
let bapak_ce = "Bapak Seno "
let ibu_ce = "Ibu Sutarsih"

let ig_co = "yogaybm"
let ig_ce = "rindamustikah"

let foto_ce = pw("rinda-yoga", "Individual 2.jpg")
let foto_co = pw("rinda-yoga", "Individual 1.jpg")
let waktunikah = "1/17/2021"

let modal = pw("rinda-yoga", "Modal.jpg")
let openlogo = "https://i.ibb.co/37H4Ggn/Logo-13.png"

let gmaps = "https://goo.gl/maps/zasCAx9XyY4b4je68"
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.8939400786076!2d112.61537291477408!3d-7.138261994845549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd80188fb0da349%3A0x4a12f9cfed4023f6!2sHotel%20Horison%20GKB%20-%20Gresik!5e0!3m2!1sid!2sid!4v1607191147420!5m2!1sid!2sid"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=Mjl1N2dubm5rdmQ4ZG91MmRuMnZka3Q5bzEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = [pw("rinda-yoga", "1.jpg"),
pw("rinda-yoga", "2.jpg"),
pw("rinda-yoga", "3.jpg"),
pw("rinda-yoga", "4.jpg"),
pw("rinda-yoga", "5.jpg"),
pw("rinda-yoga", "6.jpg"),
pw("rinda-yoga", "7.jpg"),
pw("rinda-yoga", "8.jpg"),
pw("rinda-yoga", "9.jpg"),
pw("rinda-yoga", "10.jpg"),

]



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            comment: [],
            shownew: false,
            showgift:false
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment=await getData('kepada:"rinda-yoga"')
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw("rinda-yoga", "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir} ${this.state.hadir?'sesi'+this.state.sesi:''}", jumlahhadir: "", kepada: "rinda-yoga", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, shownew, comment, sesi,showgift } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: "#69785B"
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center  px-3" style={{color:'white'}}>
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(QS Ar-Ruum:21)

                    </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'white' }}>
                                            The Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6 h-100' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2 h-100'>
                                                <div className="h-100 w-100">
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                            {inisial_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <Col xs={6}>
                                                            <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center" style={{
                                                            fontSize: '24px',
                                                            fontFamily: "'Marck Script', cursive", color: cmain
                                                        }}>
                                                            {lengkap_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                            <b>Putra dari:</b><br />
                                                            {bapak_co}
                                                            <br />
                                                        &<br />
                                                            {ibu_co}
                                                        </p>
                                                    </Item>
                                                    <Item>
                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                    </Item>
                                                </div>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: 'white' }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                MINGGU <span className="fs36">17</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: 'white' }}>
                                            <b>Akad Nikah </b><br />
                                            <span className=" fs16">

                                                08.00 WIB - Selesai
                      </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: 'white', fontSize: '72px' }}>
                                            \
                                        </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid white` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: 'white' }}>
                                            <b>Resepsi</b><br />

                                            <span className=" fs16">

                                                Sesi 1 : 09.30 – 10.30 WIB <br />
                                                 Sesi 2 : 10.30 – 11.30 WIB



                                               </span>


                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3">
                                            <b>Horison Grand Ballroom - Hotel Horison GKB

                                            <br />
                                            Jl. Kalimantan No. 12 A, Kabupaten Gresik, Jawa Timur
                                            </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Container className="py-3">
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ shownew: !shownew })}
                                            xs={11} md={4}
                                            style={{
                                                border: `2px solid white`,
                                                borderRadius: '10px'
                                            }}
                                            className="btn p-2 mx-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/2750/2750740.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                    <b>New Normal Rules</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                

                                {
                                    shownew ? (
                                        <Container className="py-3">
                                            <Item>
                                                <Col xs={12} md={6}>
                                                    <img src={covid} className="w-100 img-fluid" />
                                                </Col>
                                            </Item>
                                        </Container>
                                    ) : false
                                }
                                <Timer cmain={cmain} waktunikah={waktunikah} />



                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container className="py-3">
                                    <div className="embed-responsive embed-responsive-4by3 ">
                                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/A3aRKF1_MEU"></iframe>
                                    </div>
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            50.000 years before the sky was introduced to the sea, Allah wrote down your name next to me.<br /><br />
No matter how long it takes, how far we go, how tough it seems, then finally we find each other.
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>
                                                                    {!hadir ? false : (
                                                                        <>  <Alert variant='dark col-12 mr-4 '>
                                                                            <p className="f-14">
                                                                                Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 1 })
                                                                            }
                                                                            }
                                                                                className="pl-5">
                                                                                <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                                                                <label for="featured-3">

                                                                                    Sesi 1 : 09.30 – 10.30 WIB</label>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 2 })
                                                                            }
                                                                            } className="pl-5">
                                                                                <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                                                                <label for="featured-4"

                                                                                >
                                                                                    Sesi 2 : 10.30 – 11.30 WIB</label>
                                                                            </div>
                                                                            <p className="f-14">
                                                                                Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan

                                      </p>
                                                                        </Alert>



                                                                        </>

                                                                    )}

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </div>
                                                        </Item>
                                                    </form>

                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="py-5"
                                >

                                    <Item>
                                        <Col xs={12} md={6} style={{ backgroundColor: 'white', borderRadius: '15px' }}>
                                            <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                {comment.map(v => {
                                                    return (
                                                        <Item>
                                                            <Col xs={2}>
                                                                <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                                            </Col>
                                                            <Col xs={10}>
                                                                <Alert variant="secondary col-12">
                                                                    <p style={{ fontSize: '16px' }} className='m-0'>
                                                                        {v.pesan}
                                                                    </p>
                                                                </Alert>
                                                            </Col>
                                                        </Item>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                    </Item>


                                    <Container className="py-3" >
                                        <Item>
                                            <Col
                                                onClick={() => this.setState({ showgift: !showgift })}
                                                xs={10} md={4}
                                                style={{
                                                    border: `2px solid white`,
                                                    borderRadius: '10px'
                                                }}
                                                className="p-2 mx-md-2 mt-3 mt-md-0">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                                                        <b>Send Gift</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                        </Item>
                                    </Container>
                                </Container>
                               {
                                   showgift?( <Gift
                                    reza
                                    rinda
                                    content={
                                        [
                                            {
                                                bank: 'BRI',
                                                norek: '033901104695508',
                                                nama: 'Yoga Yuniar Bahrul Musthafa'
                                            },
                                            {
                                                bank: 'BCA',
                                                norek: '7901170286',
                                                nama: 'Rinda Mustikah'
                                            }
                                        ]
                                    }
                                    caption='For those who want to give gifts to our wedding, kindly transfer to the following accounts :'
                                    ccaption="white"
                                    color={'white'}
                                    bg={cmain}
                                    langsung
                                    alamat="Jl. Raya Brantas No. 62, Perumahan Randuagung Kec. Kebomas, Kab. Gresik 61121"
                                />
                                ):false
                               }
                                <Container classname="py-3" style={{ color: 'white' }}>
                                    <p className="text-center w-100" style={{ fontFamily: 'Patrick Hand, cursive' }} >
                                        Best Regards
                    </p>
                                    <h1 className="text-center w-100" style={{ fontFamily: 'Parisienne, cursive', }}>
                                        {inisial_ce} & {inisial_co}
                                    </h1>
                                </Container>
                                <Foot ig={logoig} />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

